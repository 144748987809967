import React from "react";
import Link from "next/link";
import { Card } from "react-bootstrap";

import Image from "./CustomImage";

const BluredCategoryCard = (props) => {
  const { item, className } = props;

  return (
    <Card className={`${className} border-0 rounded-0 blured-card`} key={item.properties.link}>
      <div className="dark-overlay">
        <Image
          src={item.properties.image}
          layout="fill"
          className="bg-image"
          alt={`Image for ${item.properties.title} category`}
          loading="eager"
          priority={true} />
      </div>

      <div className="p-4 overlay-content">
        <Card.Title className="mb-5 ">
          <Link className="h5" href={item.properties.link}>
            {item.properties.title}
          </Link>
        </Card.Title>
        <ul className="list-unstyled">
          {item.properties.categories.map((category) => (
            <li className="py-1 mb-2" key={category.link}>
              <Link href={category.link}>
                {category.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Card>);
}

export default BluredCategoryCard;