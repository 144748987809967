import React, { useEffect, useState } from "react"

import { Navigation, Pagination, Autoplay, EffectFade } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import BluredCategoryCard from "./BluredCategoryCard"

const SwiperComponent = (props) => {
  const [domLoaded, setDomLoaded] = useState(false)

  useEffect(() => {
    setDomLoaded(true)
  }, [])

  const breakpoints = []

  if (props.sm) {
    breakpoints[565] = {
      slidesPerView: props.sm,
    }
  }
  if (props.md) {
    breakpoints[768] = {
      slidesPerView: props.md,
    }
  }
  if (props.lg) {
    breakpoints[991] = {
      slidesPerView: props.lg,
    }
  }
  if (props.xl) {
    breakpoints[1200] = {
      slidesPerView: props.xl,
    }
  }
  if (props.xxl) {
    breakpoints[1400] = {
      slidesPerView: props.xxl,
    }
  }
  if (props.xxxl) {
    breakpoints[1600] = {
      slidesPerView: props.xxxl,
    }
  }
  const params = {
    className: props.className,
    modules: [Navigation, Pagination, Autoplay, EffectFade],
    slidesPerView: props.perView ? props.perView : 1,
    effect: props.effect,
    allowTouchMove: props.allowTouchMove === false ? false : true,
    spaceBetween: props.spaceBetween ? props.spaceBetween : 0,
    centeredSlides: props.centeredSlides,
    roundLengths: props.roundLengths,
    loop: props.loop,
    speed: props.speed ? props.speed : 400,
    parallax: props.parallax,
    breakpoints: breakpoints,
    autoplay: props.autoplay,
    pagination: props.pagination !== undefined && {
      type: "bullets",
      clickable: true,
      dynamicBullets: true,
    },
    navigation: props.navigation !== undefined,
  }
  return props.data
    ? domLoaded && (
      <Swiper {...params}>
        {props.data.map((slide, index) =>
          <SwiperSlide key={index} className="px-2 h-auto">
            <div className="w-100 h-100 hover-animate">
              <BluredCategoryCard item={slide} className={index < 6 ? 'text-white ms-4' : 'ms-4'} />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    )
    : "loading"
}

export default SwiperComponent
