import React, { useState, useEffect } from "react"
import Link from "next/link"
import { Container, Row, Col } from "react-bootstrap"
import SwiperCategory from "./SwiperCategory"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons"

import data from "../data/categories-main.json"
import categoriesItemsData from "../data/homepage-categories-item.json"
import BluredCategoryCard from "./BluredCategoryCard"

const Categories = (props) => {
  const categories = categoriesItemsData.features;

  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    function handleResize() {
      if (typeof window === "object") {
        setIsMobile(window.innerWidth <= 768);
      }
      else setIsMobile(false);
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  });

  return (
    <>
      <section className={`pt-6 pb-3 ${props.greyBackground ? " bg-gray-100" : ""}`}>
        <Container>
          <Row className="mb-5">
            <Col md="6">
              <p className="subtitle text-secondary">{data.subTitle}</p>
              <h2>{data.title}</h2>
            </Col>
            <Col
              md="6"
              className="d-lg-flex align-items-top justify-content-end"
            >
              {data.buttonLink && (
                <Link href={data.buttonLink}>
                  <a className="text-muted text-sm">
                    {data.button}
                    <FontAwesomeIcon icon={faAngleDoubleRight} className="ms-2" />
                  </a>
                </Link>
              )}
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-black py-5">
        {isMobile ?
          <Row>
            <SwiperCategory
              className="swiper-container-mx-negative pt-3 pb-5 pl-3"
              perView={1.5}
              spaceBetween={10}
              roundLengths
              data={categories}
              cards
              loop
              pagination
            />
          </Row>
          :
          (<Container>
            <Row className="g-4 g-xl-5">
              {categories.map((item, index) => (
                <Col key={item.properties.title} className="col-md-6 col-lg-4 col-xl-3">
                  <BluredCategoryCard item={item} className={index < 6 ? 'text-white' : ''} />
                </Col>
              ))}
            </Row>
          </Container>)
        }
      </section>
    </>
  )
}

export default Categories